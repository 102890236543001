import { MediaFile } from "../media.types";
import { Badge, Image, MarketingImage } from "~/types/api/pages.types";
import { ITranslation } from "@/types/components.types";

export interface IBlockTranslation<T> extends ITranslation {
  id: number;
  subtitle: string;
  content: T;
}
export interface ColorPickerData {
  alpha: number;
  hex: string;
  hexa: string;
  hsla: { h: number; s: number; l: number; a: number };
  hsva: { h: number; s: number; v: number; a: number };
  hue: number;
  rgba: { r: number; g: number; b: number; a: number };
}
export interface BlockType {
  name: string;
  identifier: string;
}

export interface BlockContent {
  title?: string;
  content?: string;
}
export interface BlockCategoriesContent {
  title?: string;
  subTitle?: string;
}

export interface BlockEntity {
  active?: boolean;
  id?: number | string;
  link?: string;
  sort_order: number;
}
export enum BlockEntitiesContentTypesE {
  materials = "materials",
  collections = "collections",
  categories = "categories",
}
export interface BlockEntitiesContent {
  title: string;
  materialCategory?: string | null;
  type: BlockEntitiesContentTypesE;
  entities?: BlockEntity[];
  slidesCount?: {
    mobile: number;
    desktop: number;
  };
  tabsView: boolean;
  productView: boolean;
  gridView: boolean;
  image_brightness_filter?: string;
  card_content_position?: string;
  title_position?: string;
  imageBackgroundColor?: ColorPickerData;
  imageBackgroundColorMobile?: ColorPickerData;
}

export interface BlockSliderSlide {
  id: number;
  title?: string;
  subTitle?: string;
  text?: string;
  buttonLabel?: string;
  buttonUrl?: string;
  width?: string;
  height?: {
    mobile: string;
    desktop: string;
  };
  backgroundColor?: ColorPickerData;
  backgroundColorMobile?: ColorPickerData;
  file?: MediaFile;
  fileMobile?: MediaFile;
  height_mobile?: string;
  height_desktop?: string;
  image_position?: string;
}

export interface BlockSliderContent {
  title?: string;
  subTitle?: string;
  slides?: BlockSliderSlide[];
  rightSide?: boolean;
  bottomMobile?: boolean;
  file?: MediaFile;
  fileMobile?: MediaFile;
  slidesCount?: {
    mobile: number;
    desktop: number;
  };
  card_content_position?: string;
  title_position?: string;
  image_brightness_filter?: string;
  buttonUrl?: string;
  buttonLabel?: string;
}

export interface BlockTypeContent {
  id: number;
  customer_number: string;
  vendor_model: string;
  slug: string;
  status: number;
  base_cost?: number;
  base_compare_at: number;
  sale: boolean;
  sale_value: string;
  title: string;
  badges: Badge[];
  images: Image[];
  marketing_images: MarketingImage[];
}
export interface BlockPlpEntityType {
  id: number;
  title: string;
  slug: string;
  sku: string | null;
}

export interface BlockPlpCatalog {
  title: string;
  subTitle: string;
  categories: BlockPlpEntityType[];
  collections: BlockPlpEntityType[];
  materials: BlockPlpEntityType[];
  enable_all_filter?: boolean;
  show_categories_filters?: boolean;
  show_collections_filters?: boolean;
}

export interface AccordionItemList {
  text: string;
  title: string;
}

export interface AccordionList {
  list: AccordionItemList[];
  title: string;
}
export interface AccordionBlockData {
  list: AccordionList[];
  title: string;
}

export interface ArticlesBlockData {
  title: string;
  subTitle: string;
  linkText: string;
  link: string;
}

export interface ComparisonBlockData {
  title: string;
  subTitle: string;
  productColumn: string;
  priceColumn: string;
  statusColumn: string;
  buttonText: string;
}

export interface MarketingCollectionsSlide {
  id: number;
  title: string;
  subTitle: string;
  text: string;
  buttonLabel: string;
  buttonUrl: string;
  file: MediaFile;
  is_right?: boolean;
}
export interface MarketingCollectionsData {
  title: string;
  slides: MarketingCollectionsSlide[];
}

export interface MediaBlockData {
  file: MediaFile;
}

export enum RichTextBackgroundTypes {
  DEFAULT = 1,
  COLOR = 2,
  IMAGE = 3,
  CUSTOM = 4,
}

export interface RichTextModuleData {
  title: string;
  content: string;
  table_of_content: string;
  background_type: RichTextBackgroundTypes;
  color: any;
  file?: MediaFile;
  fileMobile?: MediaFile;
  custom_background: string;
  height_desktop?: string;
  height_mobile?: string;
  image_position?: string;
}

export interface QuestionFormModuleData {
  email: string;
  title: string;
  subject: string;
  subTitle: string;
  full_name: string;
  last_name: string;
  text_area: string;
  button_text: string;
}

export interface WishListBlockData {
  title: string;
  subTitle: string;
  productColumn: string;
  priceColumn: string;
  statusColumn: string;
  buttonText: string;
}

export type AllBlockContents =
  | BlockContent
  | BlockCategoriesContent
  | BlockSliderContent
  | BlockPlpCatalog
  | BlockEntitiesContent
  | RichTextModuleData;

export interface BlockData<T> {
  id: number;
  name: string;
  type: BlockType;
  currentTranslation?: IBlockTranslation<T>;
  translations: IBlockTranslation<T>[];
  block_type_content: BlockTypeContent[];
}

export interface BlockResponse {
  data: BlockData<AllBlockContents>;
  meta: any[];
}
